import React from 'react'
import logo from '../logo.svg'

function Header() {
  return (
    <div id="top" className='flex jcsb aic'>
      <a href="/"><img src={logo} alt="Logo" width={72} /></a>
      <a href="#contact">Contact</a>
    </div>
  )
}

export default Header
