import React, { useState } from 'react'

function Tooltip(props) {

  const [showDesc, setShowDesc] = useState(false)

  return (
    <div className='tlp_container'>
      <img
        src={props.icon}
        alt={props.step}
        onMouseEnter={() => setShowDesc(true)}
        onMouseLeave={() => setShowDesc(false)} />
        {showDesc ? <div className={`tlp ${props.border} ${props.position}`}><span>{props.step}</span><p>{props.desc}</p></div> : ''}
    </div>
  )
}

export default Tooltip
