import React, { useState, useEffect, useRef } from 'react'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import reviews from '../data/reviews'

import p01 from '../assets/img/process/p01.svg'
import p02 from '../assets/img/process/p02.svg'
import p03 from '../assets/img/process/p03.svg'
import p04 from '../assets/img/process/p04.svg'
import p05 from '../assets/img/process/p05.svg'

import pic from '../assets/img/process/kaizen.svg'

import person from '../assets/img/ways/person.svg'

import c01 from '../assets/img/ways/c01.svg'
import c02 from '../assets/img/ways/c02.svg'
import c03 from '../assets/img/ways/c03.svg'
import c04 from '../assets/img/ways/c04.svg'

import m01 from '../assets/img/ways/m01.svg'
import m02 from '../assets/img/ways/m02.svg'
import m03 from '../assets/img/ways/m03.svg'

import w01 from '../assets/img/ways/w01.svg'
import w02 from '../assets/img/ways/w02.svg'
import w03 from '../assets/img/ways/w03.svg'


import wr1 from '../assets/img/ways/wr1.svg'
import wr2 from '../assets/img/ways/wr2.svg'

import rr01 from '../assets/img/ways/rr01.svg'
import rr02 from '../assets/img/ways/rr02.svg'


import rr2 from '../assets/img/ways/rr2.svg'
import rr3 from '../assets/img/ways/rr3.svg'
import rr4 from '../assets/img/ways/rr4.svg'


import wr01 from '../assets/img/ways/wr01.svg'

import free from '../assets/img/ways/free.svg'
import paid from '../assets/img/ways/paid.svg'

import vid from '../assets/media/video.mp4'

import Tooltip from './Tooltip'
import Review from './Review'

function Content() {

  const [showVideo, setShowVideo] = useState(false)
  const [voice, setVoice] = useState(true)

  const sliderRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const slider = sliderRef.current;
    if (slider) {
      setTimeout(() => {
        slider.slickPause();
      }, 0);
    }
  }, [activeIndex]);

  const handleSlideClick = (index) => {
    setActiveIndex(index);
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    variableWidth: true,
    beforeChange: (current, next) => setActiveIndex(next)
  }

  return (
    <div className="content">
      <div className="row">
        <div className="col-md-4 text-center col-it-left">
          <div className="row">
            <div className="col-4 text-center">
              <h2 className="c-pink medium" style={{ fontSize: 18 }}>Common way</h2>
              <div className="s-40"></div>
              <Tooltip border={'pink'} icon={c01} step={1} desc={`Applying for up to 400 vacancies`} />
              <div className="s-0"></div>
              <img src={rr01} alt="row" />
              <div className="s-0"></div>
              <Tooltip border={'pink'} icon={c02} step={2} desc={'Spending 10-12 months to get a first IT lower-level job as a trainee or junior position'} />
              <div className="s-0"></div>
              <img src={rr02} alt="row" />
              <div className="s-0"></div>
              <Tooltip border={'pink'} icon={c03} step={3} desc={'Spending 1-3 years of slow career rogression to reach a middle level position'} />
              <div className="s-0"></div>
              <img src={rr02} alt="row" />
              <div className="s-0"></div>
              <Tooltip position={'up'} border={'pink'} icon={c04} step={4} desc={'Depending on the country, paying for advanced IT education to increase chances of getting hired at an advanced job, at a cost of $4,000 to $15,000'} />
            </div>
            <div className="col-4 text-center">
              <img src={person} alt="pic" style={{ margin: '40px 13px 0 -13px' }} />
              <img src={wr1} alt="icon" />
              <div className="s-0"></div>
              <img src={free} alt="free" className='my-1' />
              <div className="s-0"></div>
              <img src={wr1} alt="icon" />
              <div className="s-0"></div>
              <img src={m01} alt="icon" />
              <div className="s-0"></div>
              <img src={wr2} alt="icon" />
              <div className="s-0"></div>
              <img src={m02} alt="icon" />
              <div className="s-0"></div>
              <img src={rr2} alt="row" />
              <div className="s-0"></div>
              <img src={paid} alt="paid" className='my-1' />
              <div className="s-0"></div>
              <img src={rr3} alt="row" />
              <div className="s-0"></div>
              <img src={m03} alt="icon" />
              <div className="s-0"></div>
              <img src={rr4} alt="row" />
            </div>
            <div className="col-4">
              <h2 className="c-blue medium" style={{ fontSize: 18 }}>Our Internship</h2>
              <div className="s-40"></div>
              <Tooltip border={'blue'} icon={w01} step={1} desc={'Participating in the online virtual internships program for 6 months, Monday to Friday, 8 hours a day.'} />
              <div className="s-0"></div>
              <img src={wr01} alt="" />
              <div className="s-0"></div>
              <Tooltip border={'blue'} icon={w02} step={2} desc={'Combining 80% project practice in a small team and 20% peer-to-peer education in the online community.'} />
              <div className="s-0"></div>
              <img src={wr01} alt="" />
              <div className="s-0"></div>
              <Tooltip border={'blue'} icon={w03} step={3} desc={'After 6 months, each student will have a unique project for portfolio, presentation for job partners or may be early profit social startup'} />
            </div>
          </div>
        </div>
        <div className="col-md-4 text-center bg-grey col-it-center">
          <div className="process mt-2">
            <div className="flex rowrap aic jcfs my-1">
              <img src={p01} alt={'Test'} />
              <h3 className="process_title pr1">Test</h3>
            </div>
            <div className="flex rowrap aic jcfs my-1">
              <img src={p02} alt={'Onboarding'} />
              <h3 className="process_title pr2">Onboarding</h3>
            </div>
            <div className="flex rowrap aic jcfs my-1">
              <img src={p03} alt={'Learning'} />
              <h3 className="process_title pr3">Learning</h3>
            </div>
            <div className="flex rowrap aic jcfs my-1">
              <img src={p04} alt={'Get Serticate'} />
              <h3 className="process_title pr4">Get Serticate</h3>
            </div>
            <div className="flex rowrap aic jcfs my-1">
              <img src={p05} alt={'Job Offer'} />
              <h3 className="process_title pr5">Job Offer</h3>
            </div>
          </div>
          <div
            style={{
              borderRadius: 16,
              overflow: 'hidden'
            }}
            className="my-4"
            onMouseEnter={() => {
              setShowVideo(true)
            }}
            onMouseLeave={() => {
              setShowVideo(false)
            }}
          >
            {
              showVideo ?
                <video
                  loop
                  muted={voice}
                  autoPlay={true}
                  id="video"
                  width="100%"
                  height="auto"
                  onClick={() => setVoice(!voice)}
                >
                  <source src={vid} type="video/mp4" />
                </video> : <img src={pic} alt={'How It Works'} />
            }
          </div>
          <h2 className='big-title c-red bold upp mt-5 mb-4'>You are hired!</h2>
          <p className='c-dark medium'>You will hear this when you complete your internship in 6 months.</p>
          <ul className='text-left mb-5'>
            <li className='c-theme py-1'>√ All training is in English</li>
            <li className='c-theme py-1'>√ You need know English</li>
            <li className='c-theme py-1'>√ Fill out the form</li>
            <li className='c-theme py-1'>√ Complete a 6-month training</li>
          </ul>
        </div>
        <div className="col-md-4 col-it-right">
          <div className='it-container'>
            <Slider {...settings}>
              {reviews.map((e, index) => {
                return <Review onMouseEnter={() => handleSlideClick(index)} onMouseLeave={() => handleSlideClick(index + 1)} key="index" name={e.name} pic={e.pic} icon={e.icon} internship={e.internship} period={e.period} review={e.review} />
              })}
            </Slider>
          </div>
          <div className="s-20" id="contact"></div>
          <form action="" className="form">
            <h3 className="c-marine medium text-center" style={{ fontSize: 24 }}>Have any queries?</h3>
            <p className="c-grey m-4 text-center" style={{ lineHeight: 1.25 }}><small>Get a free counselling session from our experts</small></p>
            <p><input type="text" className="form-control" id="fullName" placeholder="Full Name" /></p>
            <p><input type="email" className="form-control" id="email" placeholder="Email" /></p>
            <p><select name="internships" id="internship" className="form-select">
              <option value="Internship" defaultValue="Internship">Internship</option>
              <option value="DevOps">DevOps</option>
              <option value="UI/UX Designer">UI/UX Designer</option>
              <option value="PreSales">PreSales</option>
              <option value="PenTest">PenTest</option>
            </select>
            </p>
            <p><textarea className="form-control h-20" placeholder="Message"></textarea></p>
            <button type="submit" className="btn btn-primary w-100 c-white bg-theme upp medium py-3">Submit</button>
            <p className='c-marine mx-4 mt-3 text-center' style={{ lineHeight: 1.25 }}><small>I agree with the terms of use and privacy policy</small></p>
          </form>
          <p className='c-grey m-4 text-center' style={{ lineHeight: 1.25 }}><small>Call us on our toll<br />free number +38(063) 264-05-19</small></p>
        </div>
      </div>
    </div>
  )
}

export default Content
