import React from 'react'

function Footer() {
  return (
    <div id="bottom" className='flex jcsb aic'>
      <p>IT-Sprout @ 2023 - All Rights Reserved</p>
      <p><a href="/privacy">Privacy Policy</a></p>
    </div>
  )
}

export default Footer
