import React from 'react'

function Review(props) {

  //const [showReview, setShowReview] = useState(false)

  return (
    <div
      className="it-card"
    >
      <div className="it-mini-card">
        <img src={props.pic} alt={props.name} />
        <h4 className="medium c-white" style={{ transform: 'rotate(-90deg)' }}>{props.internship}</h4>
        <img src={props.icon} alt='icon' />
      </div>
      <div className="it-content">
        <h4 className="it-h4">{props.name}</h4>
        <p className="it-p">{props.review}</p>
        <h5 className="it-h5">{props.period}</h5>
      </div>
    </div>
  )
}

export default Review
