import pic1 from '../assets/img/reviews/avatar01.png'
import pic2 from '../assets/img/reviews/avatar02.png'
import pic3 from '../assets/img/reviews/avatar03.png'
import pic4 from '../assets/img/reviews/avatar04.png'

import in01 from '../assets/img/reviews/devops.svg'
import in02 from '../assets/img/reviews/uiux.svg'
import in03 from '../assets/img/reviews/python.svg'
import in04 from '../assets/img/reviews/hr.svg'

const reviews = [
  {
    id: 1,
    name: 'Dmitriy Popaduk',
    pic: pic1,
    icon: in01,
    internship: 'DEVOPS',
    review: 'I agree with the terms of use and processing of my personal data. I agree with the processing of my ...',
    period: '05.17.22 - 14.12.22'
  },
  {
    id: 2,
    name: 'Irina Popova',
    pic: pic2,
    icon: in02,
    internship: 'UI/UX',
    review: 'I agree with the terms of use and processing of my personal data. I agree with the processing of my ...',
    period: '05.17.22 - 14.12.22'
  },
  {
    id: 3,
    name: 'Sergiy Solovev',
    pic: pic3,
    icon: in03,
    internship: 'Python',
    review: 'I agree with the terms of use and processing of my personal data. I agree with the processing of my ...',
    period: '05.17.22 - 14.12.22'
  },
  {
    id: 4,
    name: 'Vladislava Vetuh',
    pic: pic4,
    icon: in04,
    internship: 'HR',
    review: 'I agree with the terms of use and processing of my personal data. I agree with the processing of my ...',
    period: '05.17.22 - 14.12.22'
  },
  {
    id: 5,
    name: 'Dmitriy Popaduk',
    pic: pic1,
    icon: in01,
    internship: 'DEVOPS',
    review: 'I agree with the terms of use and processing of my personal data. I agree with the processing of my ...',
    period: '05.17.22 - 14.12.22'
  },
  {
    id: 6,
    name: 'Irina Popova',
    pic: pic2,
    icon: in02,
    internship: 'UI/UX',
    review: 'I agree with the terms of use and processing of my personal data. I agree with the processing of my ...',
    period: '05.17.22 - 14.12.22'
  },
  {
    id: 7,
    name: 'Sergiy Solovev',
    pic: pic3,
    icon: in03,
    internship: 'Python',
    review: 'I agree with the terms of use and processing of my personal data. I agree with the processing of my ...',
    period: '05.17.22 - 14.12.22'
  },
  {
    id: 8,
    name: 'Vladislava Vetuh',
    pic: pic4,
    icon: in04,
    internship: 'HR',
    review: 'I agree with the terms of use and processing of my personal data. I agree with the processing of my ...',
    period: '05.17.22 - 14.12.22'
  }
]

export default reviews
